.outlined-control .quill {
  padding: 11px;
  margin-top: -10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.outlined-control .quill:hover {
  border-color: #1e2429;
}

.outlined-control:focus-within .quill {
  border-color: #3138bb;
  border-width: 2px;
  padding: 10px;
}

.outlined-control:focus-within label.MuiFormLabel-root {
  color: #3138bb;
}

.outlined-control.error:focus-within label.MuiFormLabel-root {
  color: #e62a72;
}

.outlined-control.error .quill {
  border-color: #e62a72;
}

.outlined-control .quill .ql-toolbar,
.outlined-control .quill .ql-container {
  border: none;
}

.outlined-control .quill .ql-toolbar {
  border-bottom: 1px solid #ddd;
}
