:not(.calendar-day-container):-webkit-scrollbar {
  width: 8px;
}

*:hover:not(.calendar-day-container):-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar-button {
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}
::-webkit-scrollbar-track-piece {
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #dbe2e7;
}

::-webkit-scrollbar-corner {
}

::-webkit-resizer {
}

.not-clickable {
  user-input: none;
  pointer-events: none;
}
